import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-round-rock-texas.png'
import image0 from "../../images/cities/scale-model-of-photography-by-roy-allen-stagg-in-round-rock-texas.png"
import image1 from "../../images/cities/scale-model-of-inner-space-cavern-in-round-rock-texas.png"
import image2 from "../../images/cities/scale-model-of-chisholm-trail-crossing-park-in-round-rock-texas.png"
import image3 from "../../images/cities/scale-model-of-creekmont-west-park-in-round-rock-texas.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Round Rock'
            state='Texas'
            image={image}
            lat='30.5252'
            lon='-97.666'
            attractions={ [{"name": "Photography by Roy Allen Stagg", "vicinity": "2122 Bandera Path, Round Rock", "types": ["point_of_interest", "establishment"], "lat": 30.5029593, "lng": -97.62508389999999}, {"name": "Inner Space Cavern", "vicinity": "4200 N Interstate 35 Frontage Rd, Georgetown", "types": ["point_of_interest", "establishment"], "lat": 30.6079091, "lng": -97.68813639999996}, {"name": "Chisholm Trail Crossing Park", "vicinity": "500 Chisholm Trail Rd, Round Rock", "types": ["park", "point_of_interest", "establishment"], "lat": 30.512077, "lng": -97.6894934}, {"name": "Creekmont West Park", "vicinity": "1006 Oakridge Dr, Round Rock", "types": ["park", "point_of_interest", "establishment"], "lat": 30.4973731, "lng": -97.7051831}] }
            attractionImages={ {"Photography by Roy Allen Stagg":image0,"Inner Space Cavern":image1,"Chisholm Trail Crossing Park":image2,"Creekmont West Park":image3,} }
       />);
  }
}